@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }
}

/*@layer base {*/
/*  * {*/
/*    @apply border-border;*/
/*  }*/
/*  body {*/
/*    @apply font-sans antialiased bg-background text-foreground;*/
/*  }*/
/*}*/


.element {
  @apply cursor-pointer p-3 w-full font-light rounded-sm hover:brightness-90;
}

/* Helium to the top right  */
.element-2 {
  @apply col-start-[-2];
}

/* Boron & Alumunium to the right  */
.element-5,
.element-13 {
  @apply col-start-13;
}

/* Lanthinide & Actinoid split  */
.element-58,
.element-90 {
  @apply col-start-3;
}

.noble {
  @apply bg-[#8488c4];
}

/* Alkali Metal  */
.alkali {
  @apply bg-[#a2b242];
}

/* Alkaline Earth Metal  */
.alkaline {
  @apply bg-[#70c097];
}

/* Transition Metal  */
.transition {
  @apply bg-[#81c4fb];
}

/* Polyatomic Non Metal  */
.polyatomic {
  @apply bg-[#84949c];
}

/* Diatomic Non Metal  */
.diatomic {
  @apply bg-[#b99753];
}

/* Post Transition Metal  */
.post-transition {
  @apply bg-[#a9a9a9];
}

/* Metalloid  */
.metalloid {
  @apply bg-[#d3635d];
}

/* Lanthanide  */
.lanthanide {
  @apply bg-[#ffe634] mt-4;
}

/* Actinide  */
.actinide {
  @apply bg-[#fcb52f];
}

/* The Unknown Element  */
.unknown {
  @apply bg-[#cff];
}

.element-57 {
  @apply mt-0;
}

.active-element {
  @apply border-orange-500 bg-orange-500 text-white font-medium hover:brightness-50;
}

.disabled {
  @apply opacity-20 hover:cursor-default hover:brightness-100;
}

.element-box {
  @apply relative col-[3/5] row-[2/4] px-1 flex flex-col items-center justify-center gap-2 font-light rounded-sm;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-icon {
  color: white !important;
}


